/* Alap stílusok */
body {
  background-color: #ffffff; /* Fehér háttér */
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Navigációs sáv */
nav {
  background-color: #daeff1; /* Menüpont háttérszíne */
  padding: 10px;
  display: flex;
  justify-content: center;
  border-radius: 10px; /* Lekerekített sarkok */
}

/* Menüpontok */
nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin: 0 15px; /* Távolság a menüpontok között */
}

nav ul li a {
  text-decoration: none;
  color: #fb2de3; /* Menüpont szöveg színe */
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

nav ul li a:hover {
  background-color: #b0e0e6; /* Hover háttérszín */
}
